import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, PancakeToggle, Toggle, Flex, Modal, InjectedModalProps, Image, ThemeSwitcher, Button } from '@pancakeswap/uikit'

import { useTranslation } from 'contexts/Localization'

import eth from '../../../eth.png'
import bsc from '../../../bsc.png'
import avalanche from '../../../avalanche.png'
import arbitrum from '../../../arb.png'
import zksync from '../../../zksync.png'



const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  max-height: 400px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: none;
  }
`

const NetworkModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  

  const { t } = useTranslation()

const [network, setNetwork] = useState("");
 

  return (
    <Modal
      title="Network"
      headerBackground="gradients.cardHeader"
      onDismiss={onDismiss}
      style={{ maxWidth: '420px' }}
      className="setting_title"
    >
      <ScrollableContainer className="pop_up_text">
        <Flex pb="24px" flexDirection="column">
          <Text bold textTransform="uppercase" fontSize="14px" color="primerose" mb="24px">
            {t('Select a Network')}
          </Text>
          <div className='chain_modal'>
            <Flex pb="12px" className='netword_div'>
                <Button className="network_btn" onClick={()=>{setNetwork("ETH")}}>
          <Image src={eth} alt="Flipper bunny" width={30} height={30} />
          <Text color="secondary" className='network_text'>Ethereum</Text>
          </Button>
          </Flex>

          <Flex pb="12px" className='netword_div'>
          <Button className="network_btn" onClick={()=>{setNetwork("BNB")}}>
          <Image src={bsc} alt="Flipper bunny" width={30} height={30} />
          <Text color="secondary" className='network_text'>Binance Smart Chain</Text>
          </Button>
          </Flex>

          <Flex pb="12px" className='netword_div'>
          <Button className="network_btn" onClick={()=>{setNetwork("Optimism")}}>
          <Image src={avalanche} alt="Flipper bunny" width={30} height={30} />
          <Text color="secondary" className='network_text'>Avalanche</Text>
          </Button>
          </Flex>

          <Flex pb="12px" className='netword_div'>
          <Button className="network_btn" onClick={()=>{setNetwork("Optimism")}}>
          <Image src={arbitrum} alt="Flipper bunny" width={30} height={30} />
          <Text color="secondary" className='network_text'>Arbitrum</Text>
          </Button>
          </Flex>

          <Flex pb="0px" className='netword_div'>
          <Button className="network_btn" onClick={()=>{setNetwork("Optimism")}}>
          <Image src={zksync} alt="Flipper bunny" width={30} height={30} />
          <Text color="secondary" className='network_text'>zkSync</Text>
          </Button>
          </Flex>

        

       
          </div>
         
        </Flex>
      
       
     
      </ScrollableContainer>
    </Modal>
  )
}

export default NetworkModal
