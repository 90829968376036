import React from 'react'
import { Flex, IconButton, CogIcon, useModal,Text, Image } from '@pancakeswap/uikit'
import SettingsModal from './SettingsModal'
import NetworkModal from './NetworkModal'
import bsc from '../../../bsc.png';
import ethimg from '../../../eth.png'

const GlobalSettings = () => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [onPresentNetworkModal] = useModal(<NetworkModal />)


  return (
    <Flex>
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <CogIcon height={24} width={24} color="textGreennew" />
      </IconButton>
      <IconButton onClick={onPresentNetworkModal} variant="text" scale="sm" mr="8px" id="open-network-dialog-button">
        {/* <CogIcon height={24} width={24} color="textGreennew" /> */}
        <Image src={ethimg} alt="Flipper bunny" width={20} height={20} />

        <Text className='chain_text'>Ethereum</Text>
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
